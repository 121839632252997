.saveBlogContainer {
    display: flex;
}

.sideBarDiv {
    width: 6.9%;
    position: relative;
    z-index: 9999;
    top: 0;
    left: 0;
}

.navContainer {
    width: 96.3%;
}


.saveBlogSection {
    left: 60px;
}

.saveBlogContent {
    padding-top: 7%;
}

.saveBlogTitle {
    color: #091E42;
    font-size: 45px;
    font-weight: 700;
}

.saveBlogTitle h2 {
    color: #091E42;
    font-size: 40px;
    font-weight: 500;
    margin-left: 60px;
}

.saveBlogdesc {
    font-weight: 500;
    font-size: 16px;
    color: #091E42;
    line-height: 35px
}

.savedItemSection {
    background-color: #F4F7FC;
    height: 101vh;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* position: relative; */
}

.savedItem {
    position: absolute;
    margin-bottom: 250px;
}

.savedItem h5 {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    font-family: 'Poppins';
}

.savedItem p {
    font-size: 12px;
    font-family: 'Poppins';
    text-align: center;
    font-weight: 300;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 20px;
}

.savedItem button {
    width: 210px;
    height: 53px;
    border: none;
    outline: none;
    color: #FFFF;
    font-weight: 500;
    font-family: 'Poppins';
    background: #1633A3;
    text-align: center;
    font-size: 15px;
    border-radius: 12px;
    margin-top: 30px;
}

.illustration {}

.saveBlogContent .sample-saved-article {
    margin-left: 60px;
    display: flex;
    margin-bottom: 50px;
}

.saveBlogContent .sample-saved-article .saved-article-image {
    width: 25%
}

.saveBlogContent .sample-saved-article .saved-article-image img {
    width: 100%;
    height: 220px
}

.saveBlogContent .sample-saved-article .saved-article-body {
    width: 55%;
    padding-left: 25px;
    padding-right: 25px;
}

.saveBlogContent .sample-saved-article .saved-article-body h2 {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    min-height: 60px;

}

.saveBlogContent .sample-saved-article .saved-article-body p {
    margin-top: 20px;
    margin-bottom: 20px;
}

.saveBlogContent .sample-saved-article .saved-article-body .saved-article-actions {
    display: flex;
    border-bottom: 1px solid #C4C4C4;
}

.saveBlogContent .sample-saved-article .saved-article-body .saved-article-actions small {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
}

.saveBlogContent .sample-saved-article .saved-article-body .saved-article-actions small .action-icons {
    font-size: 25px;
    margin-right: 10px;
}

.saveBlogContent .sample-saved-article .saved-article-body .saved-article-actions small h5 {
    font-size: 15px;
    padding-top: 7px;
}

.saveBlogContent .sample-saved-article .saved-article-read {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.saveBlogContent .sample-saved-article .saved-article-read h3 {
    padding-top: 7px;
}

.saveBlogContent .sample-saved-article .saved-article-read div {
    margin-bottom: 25px;
}

.saveBlogContent .sample-saved-article .saved-article-read a {
    font-size: 16px;
    color: #303AF2;
    border-bottom: 2px solid #303AF2;
}

@media screen and (max-width:1024px) {}

@media screen and (max-width:768px) {
    .saveBlogTitle h2 {
        margin-left: 20px;
    }

    .saveBlogContent .sample-saved-article {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (max-width:600px) {
    .saveBlogContent .sample-saved-article {
        flex-direction: column;
    }

    .saveBlogContent .sample-saved-article .saved-article-image {
        width: 100%
    }

    .saveBlogContent .sample-saved-article .saved-article-body {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .saveBlogContent .sample-saved-article .saved-article-read {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}