.footerIcons{
    display: none;
} 

@media screen and (max-width:1024px) {
    .footerIcons{
        display: flex;
        justify-content: space-evenly;
        position: fixed;
        bottom: 0;
        width:100%;
        border-top: 1px solid grey;
        background-color: #fff;
        text-align: center;
        padding-top: 20px;
    }

    .footerIcons p{
        font-size: 20px;
    }

    .footerIcons ul {
        /* margin-top:40px; */
        display:flex !important;
        justify-content:space-evenly;
        align-items:center;
        /* bottom:0; */
        padding-top:12px;
    }
    
    
     .footerIcons ul li{
        /* padding:18px; */
        align-items: center;
        text-align: center;
        margin-bottom:0;
        padding-bottom: 0;
    } 
    
}