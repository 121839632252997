@font-face {
    font-family: "GTWalsheim-Medium";
    src: url("../fonts/GTWalsheimPro-Medium.ttf")
}

.pub-body , .pub-heading{
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis !important;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.pub-heading{
    min-height: 50px;
}

.containerOurBlog {
    display: flex;
}

.containerNavBlog {
    display: flex;
}

.sideBarDivBlog {
    width: 6.9%;
    position: relative;
    z-index: 9999;
    top: 0;
    left: 0;
}

.navContainerBlog {
    width: 96.3%;
}

.blogContainer {
    padding-top: 4%;
}


.ourBlogSection {
    margin-left: 60px;

}

.blogTitle h2 {
    color: #091E42;
    font-size: 40px;
    font-weight: 500;
}

.desc {
    font-weight: 400;
    font-size: 16px;
    color: #091E42;
    line-height: 35px
}

.blogImg {
    width: 100%;
    height: 100%;
}


.blogImg .position {
    width: 100%;
    height: 100%;
    /* position: relative; */
}


.position {
    background: url("../identityPass.png") center center no-repeat;
    background-size: cover;
    height: 100%;
    width: 93% !important;
    margin-right: 140px;
    border-radius: 10px;
}

.cards {
    /* z-index: 100; */
    /* position: absolute; */
    padding: 55px;
}

.position .card {
    background: #FFf;
    min-height: 330px;
    border-radius: 8px;
}



.card-body {
    padding: 25px;
}

.card-title {
    font-weight: bold;
    color: #091E42;
    line-height: 20px;
    font-size: 17px;
    /* max-width: 170px; */

}

.card-text {
    font-weight: 400;
    font-size: 12px;
    margin-top: 25px;
    line-height: 21px;
    width: 250px;
    color: #000000;
}

hr.raise {
    color: #B3BAC5;
    margin-top: 20px;
}

.calender {
    display: flex;
}

.calender p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 11px;
    margin-left: 4px;
    font-weight: 500;
}

.calenderIcon {
    color: rgba(0, 0, 0, 0.7);
    font-size: 11px;
}

.card span {
    font-size: 10px;
    /* margin-right: 60px; */
    color: rgba(0, 0, 0, 0.7);
}

.allIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.moon {
    font-size: 5px;
    margin-right: 3px;

}

.card {
    max-width: 19rem;
}

.card h6 {
    margin-top: 50px;
    font-size: 16px;
    color: #303AF2;
    font-weight: bold;
    text-decoration: underline;
    /* line-height:6.98; */

}

.articleSection {
    display: flex;
    flex-wrap: wrap;
    padding-top: 70px;
    padding-left: 60px;
    padding-right: 30px;
}

.article {
    width: 30%;
    margin-right: 30px;
    padding-bottom: 30px;
    /* background:blue; */

}

.article img {
    width: 100%;
    height:250px

}

.article h3 {
    font-size: 16px;
    font-family: 'GTWalsheim-Medium';
    font-weight: 900;
    margin-bottom: 20px;
    width: 100%;
    line-height: 23px;
    margin-top: 20px;

}

.article p {
    font-size: 13px;
    max-width: 330px;
    font-family: 'Roboto';
    line-height: 18px;
}

.firstArticleSplit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.firstArticleSplit span {
    background: #E3E6FF;
    height: 33px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    font-size: 11px;
    font-weight: 500;
}


.footerII {
    display: none;
}


.firstArticleSplit .fullmoon {
    font-size: 10px;
    margin-right: 11px;
}

.firstArticleSplit h4 {
    border-bottom: 2px solid #303AF2;
    color: #303AF2;
    font-size: 17px;

}


@media screen and (max-width: 1024px) {
    .navdiv {
        display: block !important;
    }

    .articleSection {
        display: flex;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 0px;
    }

    .article {
        width: 30%;
        /* padding-left:20px; */
        /* padding-right: 20px; */
    }
    
    .sideBarDivBlog {
        display: none;
    }

    .ourBlogSection{
        margin-left: 30px;
    }

    .position{
        width:97% !important
    }


    .footerII {
        display: none;
    }

}

@media screen and (max-width: 768px) {
    .sideBarDivBlog {
        display: none;
    }

    .navContainerBlog {
        width: 100%;
    }

    .navdiv {
        display: none;
    }


    .blogContainer {
        padding: 7%;

    }

    .position {
        margin-right: 0;
        width: 95% !important;
    }

    .position img {
        width: 100%;
        min-height: 100%;
    }

    .ourBlogSection {
        margin-left: 0px;
        margin-right: 0px;

    }

    .containerOurBlog {
        padding-right: 0px;
        margin-right: 0px;
    }

    .articleSection {
        flex-wrap: wrap;
        padding-left: 0px;
        padding-right: 0px;
    }

    .article {
        padding-bottom: 25px;
        width: 45%
    }

    .footerII {
        display: block;
    }

}


@media screen and (max-width: 600px) {
    .cards {
        padding: 10px !important;
    }

    .pub-body , .pub-heading{
        overflow: hidden;
        max-width: 90%;
        text-overflow: ellipsis !important;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
    .articleSection {
        display: flex;
        flex-direction: column;
    }

    .article {
        padding-bottom: 30px;
        width: 100%;
    }

    .position {
        width: 100% !important
    }

    .footerII {
        display: block;
    }

}