 @font-face {
    font-family: "GTWalsheim-black";
    src: url("../fonts/GTWalsheimPro-Black.ttf")
  }
  @font-face {
    font-family: "GTWalsheim-bold";
    src: url("../fonts/GTWalsheimPro-Bold.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-UltraBold";
    src: url("../fonts/GTWalsheimPro-UltraBold.ttf")
  }


  @font-face {
    font-family: "GTWalsheim-UltraLight";
    src: url("../fonts/GTWalsheimPro-UltraLight.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-Thin";
    src: url("../fonts/GTWalsheimPro-Thin.ttf")
  }


  @font-face {
    font-family: "GTWalsheim-Regular";
    src: url("../fonts/GTWalsheimPro-Regular.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-Medium";
    src: url("../fonts/GTWalsheimPro-Medium.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-Light";
    src: url("../fonts/GTWalsheimPro-Light.ttf")
  }

  .full-article{
    display:flex;
}

.sideBarDivArt{
    width:6.9%;
    position: relative;
    z-index: 9999;
    top: 0;
    left: 0;
}

 .navContainerArt{
    width:96.3%;
 }

.articleIcons{
    display:flex; 
    margin-top: 25px;
}

.fullArticleSection{
    display: flex; 
    justify-content:space-evenly; 
    align-items:center; 
    width:100%;
    padding-top: 130px;
    padding-right: 100px;
    /* padding-left: 55px; */
}



.articleCard{
width: 35%;
}


.articleCard h1{
    font-size:25px;
   font-family: 'GTWalsheim-bold';
}

.imageDiv{
width: 55%;
margin-left: -20px;
}



.imageDiv img{
    width: 100%;

}

.fullArticlecalender{
    display:flex; 
    justify-content:flex-start; 
    
    
}


.fullArticlecalender p{
    font-size:11px;
    color:rgba(0, 0, 0, 0.7);
    font-family: "GTWalsheim-Medium";
    font-weight:500;
    
}

.calender{
    color: rgba(0, 0, 0, 0.7);
    font-size: 13px;
}

.moonIcon .moon{
    font-size:11px;
    margin-left:20px;
    color:rgba(0, 0, 0, 0.7) ;
}

.moonIcon{
 font-size:13px;
 font-family: 'Poppins';
 font-weight:500;
 color: rgba(0, 0, 0, 0.7);
}

.socialHandle ul{
display:flex;

}

hr.articleUnderline{
    color:#B3BAC5;
    margin-top: 25px;
    width: 100%;
    height: 1px;
    font-family: 'GTWalsheimPro-Light';
}



.fullArticlecalender{

}

.socialList{
    display:flex;
    justify-content: center;
    align-items: center;
    background:#FFFFFF;
    width: 60px;
    height:50px;
    border:1px solid #DFE1E6; 
    overflow: hidden;
}

.facebook{
    border-bottom-left-radius:8px;
    border-top-left-radius: 8px;
}

.socialHandle{
    margin-top: 40px;
}



.socialHandle button{
    height:50px;
    width: 110px;
    display:flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    background:#DFE1E6;
    border-bottom-right-radius:10px;
    border-top-right-radius: 10px;
    font-size: 15px;
    color: #000000;
    font-family: 'GTWalsheim-Medium';
    font-weight:500;

}

.share{
font-size: 18px;
margin-right: 10px;
}


.blogTexts{
    width: 100%;
    background:#f4f7fc;
    margin-top: -50px;
    padding-bottom: 60px;
    position: relative;
    z-index: -1;
}

.large-text{
    /* display: flex;
    justify-content:center;
    align-items:center; */
    width: 90%;
    /* padding-left: 120px; */
    text-align: justify;
    padding-top:75px ;
    margin: 0 auto;

}


.large-text p{
    padding-top: 15px;
    font-family: "GTWalsheim-Medium";
    color: #1A1A1A;
    max-width: 1125px;
    text-justify: left;
    line-height:30px;
    font-size:16px;
}

.large-text img{
    width: 100% !important;
    height:100% !important
}



.emoji{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.emoji h3{
    font-size: 22px;
}


.emoji ul{ 
display: flex;
margin-top:20px;
}

.emoji li{
    padding: 0px;
    font-size:50px;
}

.allDownloadArticle{
    display: flex;
}

.downloadSection{
    padding-bottom: 50px;
    width: 100%;
    display: flex;
    position: relative;
    padding-left:50px ;
    padding-right: 50px; 
}


.downloadSection h4{
    font-family: 'GTWalsheim-Medium';
    font-size:30px;
    padding-bottom:30px;
    margin-top: 10px;
}



.download{
    width:330px;
    margin-right: 20px;
    margin-bottom: 40px;
}

.download-btn{
    display: flex;
    padding-top:25px;
}

.download h6{
    font-size:16px;
    font-family: 'GTWalsheim-Medium';
    font-weight: 900;
    width: 100%;
    line-height:23px;
    margin-top: 20px;
}



 .download p{
    font-size: 13px;
    max-width: 330px;
    font-family: 'Roboto';
    line-height: 18px;
    margin-top: 15px;
}

.download img{
    width: 100%;
    height:250px;
} 

.preview-btn{
  width: 130px;
  height:45px;
  color:#303AF2;
  outline: none;
  border: 1px solid #303AF2;
  background-color: #ffffff;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 15px;
}

.down-pdf{
    width: 130px;
    height:43px; 
    color:#303AF2;
    outline: none;
    border: none;
    background: #DEEBFF;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 15px;
    margin-left: 10px;
}

.articleFull{
    width:350px;
    /* margin-top: -50px; */
    margin-bottom: 40px;
    margin-right: 20px;
    padding-right: 20px;
}

.articleFull img{
    width: 100%;
    height:250px;
    
}

.articleFull h3{
    font-size:16px;
    font-family: 'GTWalsheim-Medium';
    font-weight: 900;
    margin-bottom:20px;
    width: 400;
    line-height:23px;
    margin-top: 20px;   
}

.articleFull p{
    font-size: 13px;
    max-width: 330px;
    font-family: 'Roboto';
    line-height: 18px;
}

.firstArticleSplitFull{
    display: flex;
    justify-content:space-between;
    align-items:center;
    margin-top: 35px;
}

.firstArticleSplitFull span{
background:#E3E6FF;
height: 33px;
width: 110px;
display: flex;
justify-content:center;
align-items: center;
border-radius: 24px;
font-size: 11px;
font-weight: 500;
}


/* .footerIII{
    display:none;
} */


.firstArticleSplitFull .fullmoon{
    font-size:10px;
    margin-right: 11px;
}

.firstArticleSplitFull h5{
    /* border-bottom: 2px solid #303AF2; */
    color: #303AF2;
    font-size: 17px;
    text-decoration: underline;
}

/* sidetags start here------------------------ */
.side-action-area{
    background: #FFFFFF;
    box-shadow: -8px -8px 12px rgba(9, 30, 66, 0.1), 0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 24px 0px 0px 24px;
    padding: 20px;
    position: fixed;
    right: 0;
    top:40%;
    text-align: center;
}
.side-action-area .side-icon-bg{
    height: 50px;
    width: 50px;
    background-color: #DEEBFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer !important;
    position: relative;
    border: none;
}
.side-action-area .side-icon{
    font-size: 30px;
}
.side-action-area .side-icon-bg p{
    font-family: GTWalsheim-Bold;
}
/* sidetags ends here------------------------ */

@media screen and (max-width: 1024px){
    .sideBarDivArt{
        display:none;
    }
    .navContainerArt{
        width: 100%;
    }
    
    .navdiv{
        display:none;
    } 
    
    .fullArticleSection{
        padding-right: 30px;
        padding-left:30px;
        width: 100%;  
        justify-content: space-between;
    }


    .articleCard{
        /* width: 60%; */
    }

    .imageDiv{
        /* width: 40%;
        margin-left: -50px; */
    }



    .large-text{
        padding-left: 40px;
        padding-right: 40px;
        margin-right: 0;
        width: 100%;
    }
    .large-text p{
        width: 100%;
    
    }
    .downloadSection{
        padding-left: 20px;
        padding-right: 20px;   
    }
    .downloadSection h4{
        padding-left: 20px;  
    }


    .articleFull{
        padding-left: 20px;
        padding-right: 0;
        width: 320px;
        margin-right: 0px;
    }

    /* .footerIII{
        display: block;
    } */
}

    


@media screen and (max-width: 768px){
    .sideBarDivArt{
        display:none;
    }
    .navContainerArt{
        width: 100%;
    }
    
    .navdiv{
        display:none;
    }
    
    .fullArticleSection{
        padding-right: 20px; 
        padding-left:20px;
        width: 100%;  
    }
    

    .articleCard{
        width: 50%;
        /* height: 10%; */
    }

    .imageDiv{
        width: 50%;
    }
    hr.articleUnderline{
        width: 400px;
    }

    
    .articleCard h1{
        font-size: 18px;
        /* width: 400px; */
    }
    
    


    .large-text{
        padding-left: 20px;
        padding-right: 20px;
        margin-right: 0;
        width: 100%;
    }
    .large-text p{
        width: 100%;
    
    }
    
    .emoji h3{
    /* font-size:18px; */
    }
    
    .emoji li{
        padding: 0px;
        /* font-size:30px; */
    }
    
    .downloadContent h4{
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        margin-top: 10px;
    }


    .allDownloadArticle{
        flex-wrap: wrap;
        margin-left: 50px;
    }

    .downloadSection{ 
        padding-right:10px ;
    }

    
    .articleFull{
        width:300px;  
    }
    .articleFull img{
        width:100%;
    }


    .download{
        width:300px;
        padding-right: 0px;
        margin-right: 30px;
    }
    
    .download-btn{
        display: flex;
        padding-top:25px;
    }
    
    .articleFull{
        padding-left: 0px;
        /* padding-top: 30px; */
        margin-right: 30px;
    }

    .firstArticleSplitFull h5{
        font-size: 15px;
    }

    .blogTexts{
        margin-top: -30px;
    }

    /* .footerIII{
        display: block;
    } */
}


@media screen and (max-width: 600px) {
.sideBarDivArt{
    display:none;
}
.navContainerArt{
    width: 100%;
}

.navdiv{
    display:none;
}


.fullArticleSection{
    padding-right: 20px; 
    padding-left:30px;
    width: 100%;
    flex-direction: column-reverse;  
    /* text-align: center; */
}



.articleCard{
    margin-right: 20px;
    width:100%;
    min-height: 10%;
}

.imageDiv{
  width: 100%; 
  margin-bottom: 20px; 
}



.articleCard h1{
    font-size: 15px;
    font-family: 'GTWalsheim-Medium';
}


hr.articleUnderline{
    margin-top: 15px;  
}

.articleIcons{
    justify-content: space-between;
    margin-top: 15px;
}

.socialHandle{
    margin-top: 10px; 
}

/* .socialHandle ul{
    justify-content: center;
} */

.socialList{
    background:none;
    width: 50px;
    height:40px;
    border:none;
    margin-left: -10px;
}

.socialList > img{
    width: 23px;
}


.socialHandle button{
    height:40px;
    width: 95px;
    display:flex;
    background:#DFE1E6;
    border-bottom-right-radius:5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius:5px;
    border-top-left-radius: 5px;
    font-size: 13px;
    color: #000000;
    

}

.share{
    font-size: 15px;
    margin-left: 0px;
    }
.large-text{
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 0;
    width: 100%;
}
.large-text p{
    width: 100%;

}

.emoji h3{
font-size:18px;
}

.emoji li{
    padding: 0px;
    font-size:30px;
}

.downloadContent h4{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    margin-top: 10px;
}
.allDownloadArticle{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: unset;
}



.articleFull{
    padding-left: 0px;
    padding-top: 30px;
}

.download{
    width:320px;
    
}

.articleFull{
    width:320px;   
}

/* .footerIII{
    display: block;
} */
.read h5{
    font-size: 16px;
}
    
}