@font-face {
    font-family: "GTWalsheim-black";
    src: url("../fonts/GTWalsheimPro-Black.ttf")
  }
  @font-face {
    font-family: "GTWalsheim-bold";
    src: url("../fonts/GTWalsheimPro-Bold.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-UltraBold";
    src: url("../fonts/GTWalsheimPro-UltraBold.ttf")
  }


  @font-face {
    font-family: "GTWalsheim-UltraLight";
    src: url("../fonts/GTWalsheimPro-UltraLight.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-Thin";
    src: url("../fonts/GTWalsheimPro-Thin.ttf")
  }


  @font-face {
    font-family: "GTWalsheim-Regular";
    src: url("../fonts/GTWalsheimPro-Regular.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-Medium";
    src: url("../fonts/GTWalsheimPro-Medium.ttf")
  }

  @font-face {
    font-family: "GTWalsheim-Light";
    src: url("../fonts/GTWalsheimPro-Light.ttf")
  }

.publicationContainer{
    display: flex;
}

.sideBarPublication{
    width:6.9%;
    position: relative;
    z-index: 2;
    top: 0;
    left: 0;
}

.containerNavBlog{
    display: flex;
}


 .navContainer{
    width:96.3%;
 }

 
.ourPublicationContainer{
    padding-top:9%;
   
}


.ourPublicationContent{
    margin-left: 60px;
}

.publicationTitle  h2{
    color:#091E42;
    font-size: 40px;
    font-weight:500;
}

 .publicationDesc{
 font-weight: 400;
 font-size: 16px;
 color:#091E42;
 line-height: 35px;
}

.allFrauds{
    display: flex;
    flex-wrap: wrap;

    /* margin-top: 40px; */

}

.frauds{
    width:320px;
    /* margin-left: 10px; */
    margin-right: 20px;
    /* padding-right:20px ; */
}

.fraudImg{
    width:100%;
}

.fraudImg > img{
    width:100%;
    height: 250px;
}


.fraudText h6{
    font-size:16px;
    font-family: 'GTWalsheim-Medium';
    font-weight: 900;
    /* width: 400; */
    line-height:23px;
    margin-top: 20px;
}

.fraudText p{
    font-size: 13px;
    /* width: 330px; */
    font-family: 'Roboto';
    line-height: 18px;
    margin-top: 15px;
}



.allDownloadArticle{
  display:flex;
  flex-wrap: wrap;
}

.download-btn{
    display: flex;
    padding-top:25px;
    /* justify-25ontent:space-around; */
}

.fraudPdf-btn{
    width: 130px;
    height:45px;
    color:#303AF2;
    outline: none;
    border: 1px solid #303AF2;
    background-color: #ffffff;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 15px;
    cursor:pointer;
  }
  
  .fraudDown-pdf{
      width: 130px;
      height:43px; 
      color:#303AF2;
      outline: none;
      border: none;
      background: #DEEBFF;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 15px;
      margin-left: 10px;
      cursor:pointer;
  }

 

  .ant-modal-close-x{
    background-color: #DFE1E6;
    border-radius: 50%;
    margin-top: 10px;
    margin-right: 10px;
    color: #000;
    font-weight: 500;
    margin: 10px;
    width: 36px !important;
    height: 36px !important;
    overflow:  hidden ! important;
    
}

.anticon svg {
    display: inline-block;
    font-size: 13px; 
    margin-bottom: 10px  !important;
    font-weight: bold !important;
}





#formBasic{
    padding: 20px;
    font-size:15px;
    font-family: "Poppins";
    font-weight:400;
    color: #091E42;
}

::placeholder{
    color: #6B778C;
    font-size:  11px;
    font-weight: 400;
}

.fieldsAdd input{
    width: 370px;
    height:58px;
    outline: none;
    border: none;
    color: #091E42;
    border-bottom: 1px solid #000000;
    background-color:#EBECF0;
    padding: 20px;
    }



.modal-content{
 border: none;
position:relative;
display:block;
width: 100%;
pointer-events: auto;
background-color: #fff;
background-clip: padding-box;
/* border: 1px solid rgba(0,0,0,.2); */
 /* border-radius:none; */
outline: 0;
}


form{
margin-top: 80px;
}



.createForm > .btn-close{
display: flex;
text-decoration: none;
color:black;
background-color: #DFE1E6;
width: 48px;
height: 48px;
border-radius: 50%;
justify-content: center;
align-items: center
}

.allmodal{
padding-top: 120px;
}


.modalBody h5{
font-family: "GTWalsheim-black";
font-weight: 600;
font-size:18px;
color: black;
}

.modalBody p{
font-family: "Poppins";
font-weight: 500;
color: black;
font-size:10px;
margin-top: -10px;
}


.allForms{
    margin-top: 30px;
}

.modalBody > .allForms > .form-group > input{
margin-bottom:30px;
display: block;
border:none;
border-bottom: 1px solid #000000;
background-color: #EBECF0;
padding: 15px;
width: 100%;
/* font-size: 10px; */
font-weight: 400;
line-height: 1.5;
border-radius: 0rem;
font-family: "Poppins";
transition: none;
outline: none;
}

.modalButton{
display: flex;
justify-content: flex-end;
margin-top:50px;
}


.bttn{
    color: #fff;
    background-color:#1633A3;
    border-radius: none;
    outline:none;
    border: none;
    width: 150px;
    border-radius:3px;
    height: 52px;
    font-family: "Poppins";
    font-weight: 500;
    top: 310px;
    font-size:11px ;
    margin-bottom:30px;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


   .thanksContainer{
        display: flex;
        justify-content: center; 
        align-items: center;
        margin-top:50px; 
        text-align: center;
    }

    .modalImg{
        
    }


    .thanks h5{
        font-family: "GTWalsheim-black";
        font-weight: 600;
        font-size:18px;
        color: black;
        }

        
        
        .thanks p{
        font-family: "Poppins";
        font-weight: 500;
        color: black;
        font-size:10px;
        /* margin-top: -10px; */
        }
        
    
    
    .thanks > .bttn{
        color: #fff;
        background-color:#1633A3;
        border-radius: none;
        outline:none;
        border: none;
        width: 150px;
        border-radius:3px;
        height: 52px;
        font-family: "Poppins";
        font-weight: 500;
        top: 310px;
        font-size:11px ;
        margin-bottom:30px;
        cursor:pointer;
    }

* {
    --switch-height: 27px;
    --switch-padding: 8px;
    --switch-width: calc((var(--switch-height) * 3) - var(--switch-padding));
    --slider-height: calc(var(--switch-height) - var(--switch-padding));
    --slider-on: calc(var(--switch-height) - var(--switch-padding));
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: var(--switch-width);
    height: var(--switch-height);
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    content: "";
    position: absolute;
    height: var(--slider-height);
    width: var(--slider-height);
    left: calc(var(--switch-padding) / 2);
    bottom: calc(var(--switch-padding) / 2);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked+.slider {
    background-color: #1633A3;
  }
  
  input:focus+.slider {
    box-shadow: 0 0 1px #1633A3;
  }
  
  input:checked+.slider:before {
    transform: translateX(var(--slider-on));
  }
  
  .slider.round {
    border-radius: var(--slider-height);
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .label-checkbox{
    display:flex;
    align-items: center;
    padding-top: 10px;
    font-family:'GTWalsheim-Regular';
    font-size: 12px;
    color: #000000;
  }
  .label-checkbox p{
    font-size: 11px;
    padding-top: 20px;
  }



  .footerIII{
    display:none I !important;
}

.react-pdf__Page__canvas{
  width:100% !important;
  height:100% !important;
}


  
  @media screen and (max-width: 1024px){
    .sideBarPublication{
        display: none !important;
        width:0%;
    }

    /*.navdivCon{
        display: none;

    }


    .navContainer{
      width: 100%;
  } */

  .footerIII{
    display: none;
  }

  .ourPublicationContent{
      margin-left: 0px;
  }

  .publicationTitle{
      margin-left: 20px;
  }


  .allFraud{
      /* display: flex; */
      flex-direction: column;
      margin-top: 40px;
       padding-left: 65px;
       padding-right: 0px; 
      margin-left: 0px;
      margin-right: 0px; 
    
      
  }
  
  .frauds{
      width: 30%;
      /* display: flex; */
      padding-bottom: 10px; 
      /* justify-content:center; */
      /* align-items:center; */
      /* padding-left: 150px; */
      /* padding-right:100px; */
      margin-left: 20px;
  }

  .fraudImg{
      width: 100%;
  }

  .fraudImg > img{
      max-width:100%;
  }
  .fraudText{
      width:100%;
      /* padding-left: 13px; */
  }
  
  .fraudText > h6{
    font-size: 23px;
  }

  .fraudText > p{
    font-size: 15px;
  }

}
  




  @media screen and (max-width: 768px){
    .sideBarPublication{
        display: none !important;
        width:0%;
    }

    /* .navdivCon{
        display: none;

    } */

    .navContainer{
      width: 100%;
  }


  .footerIII{
    display: none;
  }

  .ourPublicationContent{
      margin-left: 0px;
  }

  /* .publicationTitle{
      display:flex;
      justify-content:center;
      align-items:center;
  } */


  .allFrauds{
      /* flex-direction: column;
      margin-top: 40px;
      align-items: center;
      justify-content: center;
      padding-left: 0px; */
      /* margin-left: -100px; */
      
  }
  
  .frauds{
      /* display: flex; */
      width: 40%;
      padding-bottom: 10px; 
  }


  .fraudImg{
    
  }

  .fraudImg > img{
      max-width:100%;
  }
  .fraudText{
      /* width:10%; */
      /* padding-left: 13px; */
  }

  
  .fraudText h6{
font-size: 18px;
}
  

  .fraudText p{
     font-size: 13px; 
  }
  

  .download-btn{
    padding-top:10px;
  }

}
  

  @media screen and (max-width: 600px){
      .sideBarPublication{
          display: none !important;
          width:0%;
      }

      /* .navdivCon{
          display: none;

      } */

      .navContainer{
        width: 100%;
    }

    .ourPublicationContent{
        margin-left: 0px;
    }


    .fraudText{
        margin-top:30px ;
    }

    .fraud{
        margin-left: 0px;
    }


    /* .publicationTitle{
        display:flex;
        justify-content:center;
        align-items:center;
    } */


    .allFraud{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 40px;
        overflow: hidden !important;
        margin-left: unset !important;
        margin-right: unset !important;

    }
    
    .frauds{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 10px;
        width: 89%;
        /* margin-left: 0px; */
        /* overflow: hidden !important; */
    }

    .fraudImg{
        width: 100%;
        height: 200px;
    }

    .fraudImg > img{
        height: 100%;
        max-width:100%;
    }

    .fraudText{
        width:100%;
        /* margin-right: 10px; */
        margin-top:0px;
    }

    /* .fraudText h6{
        font-size: 12px;
    } */

    .fraudText p{
        font-size:11px ;
    }
    
    .download-btn > button{
        font-size:11px ;
    }
    .publicationTitle  h2{
        color:#091E42;
        font-size: 20px;
        padding-top: 50px;
    }    

  }

  