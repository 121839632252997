.sidebarContainer{
    width:98px;
    height:100vh;
    background:#fff;
    position:fixed;
    bottom:0;
    box-shadow: 8px 8px 12px rgba(9, 30, 66, 0.1), 0px 0px 1px rgba(9, 30, 66, 0.31);
  }

  .sidebarLogo{
      display:flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
  }

  .sidebarLogo img{
      width: 50px;
      height: 50px;
  }


  .sidebarIcons ul {
      margin-top:40px;
  }

  
  .sidebarIcons ul li{
      padding:18px;
      align-items: center;
      text-align: center;
  } 

  .iconText{
      color: #303AF2;
      /* font-weight:300; */
  }

  .homeIcon{
    font-size: 33px;
    color:#303AF2;
}

 .iconlist{
      font-size: 33px;
  }
   
  .socialMedia::-webkit-scrollbar {
    display: none;
  }
   .socialMedia{
    background:black;
    width: 98px;
    height: 300px;
    bottom: 0;
    /* top: 30px; */
    position: absolute;
    /* left: 0; */
    /* top: 42%; */
    }

    .socialMedia ul{
        display:block;
        justify-content: center;
        align-items: center;
        margin-left:20px;
        margin-top: 60px;
        
    }

    .socialMedia li{
        padding:10px;
        font-size: 20px;
        color:#fff;
    } 

    .followUs{
        color:#fff;
        transform: rotate(-90deg);
        margin-top: 50px;
        margin-right: 10px;
    }

    .hyphen{
        margin-left: 10px;
    }
