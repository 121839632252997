.navbar {
   	display: flex;
   	justify-content: space-between;
   	align-items: center;
}

.mobile-logo{
	display: none;
}

.navdivCon .css-1e5wqph-MuiPaper-root-MuiAppBar-root {
   	z-index: 1 !important;
}


.l-header {
   	width: 100%;
   	height: 50px;
   	position: fixed;
   	top: 0;
   	left: 0;
   	z-index: 1;
   	background-color: black;
}


.newNotification {
   	display: flex;
   	width: 17px;
   	height: 17px;
   	background-color: #DC143C;
   	color: #fff;
   	justify-content: center;
   	font-size: 10px;
   	align-items: center;
   	font-weight: 800;
   	border-radius: 12px;
   	margin-top: -32px;
   	margin-left: 10px;
   	position: absolute;
   	box-shadow: -2px 2px 10px rgb(0 0 0 / 10%);
}

.typography {
   	position: "absolute";
   	z-index: 1;
   	color: #091E42;
   	background: #FFf;
   	font-size: 13px;
   	font-weight: 600;
   	border-radius: 24px;
   	outline: none;
   	border: none;
   	width: 84px;
   	display: flex;
   	justify-content: center;
   	align-items: center;
   	/* text-align: center; */
   	height: 36px;
   	box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31);
   	margin-left: 9%;
}



.notifyIcon {
   	color: #6B778C;
   	font-size: 18px;
}

.border {
   	width: 1px;
   	background-color: #6B778C;
   	height: 28px;
}

.username {
   	font-weight: 500;
   	font-size: 12px;
   	color: #6B778C;
   	margin-top: 5px;
}

.textbox {
   	font-size: 15px;
   	color: #091E42;
}

@media screen and (max-width:1024px) {

	.typography {
		margin-left: 0%;
	}

	.mobile-logo{
		display: block;
	}
	.mobile-logo img{
		height:35px !important;
		width: 100%;
	}


}