.containerNav {
  display: flex;
}

.sideBarDiv {
  width: 6.9%;
  position: relative;
  z-index: 9999;
  top: 0;
  left: 0;
}
.navContainer {
  width: 96.3%;
}

.homesection {
  padding-top: 6%;
  background-color: none;
}

.tags {
  /* left: 50px; */
  position: relative;
}

.title {
  margin-left: 50px;
}
.tags .titleMaking {
  color: #091e42;
  font-size: 40px;
  font-weight: 500;
}

.tags .intro {
  font-weight: 400;
  font-size: 17px;
  color: #091e42;
  line-height: 35px;
}

.topics {
  display: flex;
  margin-top: 70px;
  margin-left: 50px;
}

.topics h5 {
  margin-right: 30px;
  color: #091e42;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 60px;
}

.topics ul {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
}

.topics li {
  padding: 0 25px;
  color: #091e42;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-right: 15px;
  background-color: #f4f7fc;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newtopics {
  display: flex;
  /* max-width: 90%; */
  margin: 0 auto;
  display: none;
  position: "relative";
}

.newtopics ul {
  /* display: flex; */
}

.newtopics li {
  padding: 10px 20px;
  color: #091e42;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-right: 15px;
  background-color: #f4f7fc;
  /* height: 50px; */
  border-radius: 25px;
  text-align: center;
  /* display: flex;
    justify-content:center;
    align-items: center; */
}

.sliderContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.hidden {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  scrollbar-width: none;
  /* display:flex;  */

  scroll-behavior: smooth;
}

.hidden::-webkit-scrollbar {
  display: none;
}

.hidden ul {
  display: flex;
  /*flex-wrap: wrap;
    max-width: 95%;
    position: relative; */
}

.hidden li {
  padding: 0 25px;
  color: #091e42;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-right: 15px;
  background-color: #f4f7fc;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  margin-bottom: 80px;
}

.slider {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.blogs {
  width: 100%;
  background-color: #f4f7fc;
  /* height: 100%; */
  margin-top: 44px;
}

.typeOfBlogs {
  /* width: 100%; */
  margin-left: 50px;
  margin-right: 120px;
  padding-bottom: 113px;
}

.blogTitle {
  margin-bottom: 25px;
  margin-top: 50px;
}

.blogTitle h1 {
  color: #091e42;
  font-size: 29px;
  font-weight: 600;
}

hr.underline {
  border: none;
  height: 1.5px;
  background: #091e428b;
  margin-top: 25px;
}

.blogContent {
  width: 100%;
  height: 100%;
}

.setBlog {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.setBlog .box {
  position: relative;
  width: 600px;
  height: 320px;
  border-radius: 5px;
  padding: 200x;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0px 0px 0px 10px;
}

.box img {
  position: absolute;
  /* position: absolute; */
  width: 100%;
  height: 100%;
}

.blogItems {
  position: absolute;
  width: 55%;
}

.blogItems h5 {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 2%;
}

.blogItems p {
  color: #000;
  width: 90%;
  font-size: 13px;
  color: #fff;
  font-weight: 300;
}

.blogItems button {
  width: 53%;
  margin-top: 50px;
  height: 47px;
  border-radius: 3px;
  outline: none;
  border: 1px solid #0a459f;
  color: #0a459f;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.footer {
  display: none;
  /* margin-bottom:"-50px", */
}



@media screen and (max-width: 600px) {

  .topics {
    display: none !important;
  }

  .tags {
    padding-top: 0px;
  }

}


@media screen and (max-width: 768px) {
  .navContainer {
    width: 100% !important;
  }

  #newtopics {
    display: block;
    max-width: 90%;
  }

  .slick-arrow {
    /* top: -20px !important;
    right: 0; */
  }

  .slick-slider {
    padding-top: 20px;
  }
  .navCon {
    display: none;
  }

  .sideBarDiv {
    display: none;
  }

  .tags .title {
    /* width:100%; */
    display: inline-block;
    text-align: center;
    justify-content: center;
    margin-left: 0px;
    padding-right: 0;
  }

  .footer {
    display: block;
  }

  .title h1 {
    font-size: 25px;
  }

  .tags {
    padding-top: 40px;
    text-align: center;
    /* left: 0; */
  }
  .tags .titleMaking {
    font-size: 35px;
  }
  .topics {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .topics ul {
    display: none;
  }

  .topics h5 {
    text-align: center;
    font-size: 19px;
  }

  .blogs {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .typeOfBlogs {
    margin-left: 0;
    margin-right: 0;
    width: 90%;
    text-align: center;
    margin-top: -50px;
    padding-bottom: 50px;
  }
  .blogTitle {
    padding-top: 50px;
  }

  .blogTitle h1 {
    font-weight: 500;
    font-size: 24px;
    /* width: 250px; */
    /* text-align: center; */
  }

  /* .title{
       margin-left: 0px;
       width: 500px;
   } */

  .setBlog {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .box {
    padding-bottom: 50px;
    /* border-rad: 5px; */
  }

  .blogItems {
    /* padding-left: 20px; */
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .sideBarDiv {
    width: 10px;
  }

  .sideBarDiv {
    display: none;
  }

  .navCon {
    display: none;
  }

  .tags .intro {
    font-weight: 400;
    font-size: 20px;
    color: #091e42;
    line-height: 35px;
  }

  .navContainer {
    width: 100% !important;
  }

  .title {
    padding-top: 50px;
    /* padding-right: 0; */
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    /* justify-content:center; */
  }

  .topics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .newtopics {
    display: hidden;
  }

  .tags {
    /* padding-top:40px;
        text-align: center; */
    margin-left: 0;
  }

  .topics {
    display: flex;
    margin-left: 0px;
  }

  .topics ul {
    max-width: 90%;
  }

  .topics li {
    margin-bottom: 15px;
  }

  .blogs {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .typeOfBlogs {
    margin-left: 0;
    margin-right: 0;
    width: 90%;
    text-align: center;
    margin-top: -50px;
  }

  .blogTitle {
    padding-top: 20px;
  }

  .blogTitle h1 {
    font-weight: 500;
    font-size: 26px;
    /* width: 250px; */
    /* text-align: center; */
  }

  .setBlog {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .box {
    padding-bottom: 50px;
    /* border-rad: 5px; */
  }

  .blogItems {
    /* padding-left: 20px; */
    text-align: center;
  }

  .footer {
    display: block;
  }
}
